import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const MainContainerStyled = styled.div`
  ${() => css`
    height: 100%;
    display: flex;
    flex-direction: row;
    transition: background-color 0.3s;
    overflow: hidden;

    & > .content {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 0px;
      overflow-x: hidden;
      & > * {
        &:nth-of-type(2) {
          overflow: auto;
          @media screen and (max-width: 1200px) {
            padding-top: 1rem;
          }
          @media screen and (max-width: 768px) {
            padding-top: 0.5rem;
          }
        }
      }
    }
  `}
`;
