import { get, isFunction, kebabCase } from 'lodash';
import React from 'react';
import { Button } from '../../button/Button';
import { useGridContext } from '../context/context';

function downloadFile(fileName, data) {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}

function serialiseCellValue(value) {
  if (typeof value === 'string') {
    const formattedValue = value.replace(/"/g, '""');
    return formattedValue.includes(',') ? `"${formattedValue}"` : formattedValue;
  }
  return value;
}

const ExportToCsv = ({ rows, sort, filters, columns, exportFileName = 'connex-export' }) => {
  const { gridName } = useGridContext();

  const handleClick = React.useCallback(() => {
    const headerRow = `${columns
      .reduce((acc, column) => {
        if (column.export === false) return acc;
        acc.push(...[].concat(column.exportColumnNames || column.name));
        return acc;
      }, [])
      .join(',')}\n`;

    const content = headerRow.concat(
      rows
        .map(row => {
          return columns.reduce((acc, column) => {
            if (column.export === false) return acc;

            if (isFunction(column.export)) {
              acc.push(...[].concat(column.export(row)));
            } else if (isFunction(column.getValue)) {
              acc.push(...[].concat(column.getValue(row)));
            } else {
              acc.push(get(row, column.export || column.key, ''));
            }
            return acc;
          }, []);
        })
        .map(row => row.map(serialiseCellValue).join(','))
        .join('\n')
    );

    downloadFile(`${exportFileName}.csv`, new Blob([content], { type: 'text/csv;charset=utf-8;' }));
  }, [rows, columns]);

  return (
    <Button
      size="small"
      onClick={handleClick}
      metricId={gridName ? `${kebabCase(gridName)}-download-csv` : undefined}
      data-testid="download-csv-button"
    >
      Download CSV
    </Button>
  );
};

export default ExportToCsv;
