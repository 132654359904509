import { useNavigate, useParams } from 'react-router';
import { Button, Typography } from '@mui/material';
import { Core } from 'connex-cds';
import styled from 'styled-components';
import { useGetInsightsWorkbookUrl } from '../../query-hooks/insights';

const StyledInsightsViewer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  .insights-iframe {
    flex: 1;
    min-height: 0;
    border: none;
  }
`;

export const InsightsViewer = () => {
  const { insightsWorkbookRef } = useParams();
  const navigate = useNavigate();
  const { data, isFetching, isRefetching } = useGetInsightsWorkbookUrl(insightsWorkbookRef);

  return (
    <Core.Spinner spin={isFetching || isRefetching}>
      <StyledInsightsViewer>
        <div>
          <Button variant={'contained'} onClick={() => navigate('..')}>
            <span
              className={'fa-solid fa-arrow-left'}
              style={{
                marginRight: '0.25rem',
              }}
            />
            Go Back
          </Button>
        </div>
        {data?.url && <iframe src={data?.url} className={'insights-iframe'} title="Insights Viewer Title" />}
      </StyledInsightsViewer>
    </Core.Spinner>
  );
};
